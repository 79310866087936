import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AnimatedText from '../../animation/AnimatedText';
import Aos from 'aos';
import 'aos/dist/aos.css';
import baseUrl from '../../../baseUrl';
import CustomCursor from '../../CustomCursor';

class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posts: [], // To store fetched blog posts
      bannerData: {
        sub_heading: '',
        main_heading: '',
      }, // To store fetched banner data
      loading: true, // Loading state
      totalPages: 1, // To store the total number of pages
      currentPage: 1, // To store the current page number
    };
  }

  handleMouseEnter = () => {
    CustomCursor.setHovered(true);
  };

  handleMouseLeave = () => {
    CustomCursor.setHovered(false);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchBlogPosts();
    this.fetchBannerData();

    Aos.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Set to true if you want the animation to occur only once
    });
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  fetchBlogPosts = async (page = 1) => {
    this.setState({ loading: true });
    try {
      const perPage = 6; // Adjust the number of posts per page as needed
      const response = await fetch(
        `${baseUrl}/wp-json/wp/v2/posts?per_page=${perPage}&page=${page}`
      );
      const data = await response.json();
      const postsWithImages = await Promise.all(
        data.map(async (post) => {
          if (post.featured_media) {
            const imageResponse = await fetch(
              `${baseUrl}/wp-json/wp/v2/media/${post.featured_media}`
            );
            const imageData = await imageResponse.json();
            post.featured_image = imageData.source_url;
          }
          return post;
        })
      );
      this.setState({
        posts: postsWithImages,
        loading: false,
        totalPages: parseInt(response.headers.get('X-WP-TotalPages'), 10), // Extract total pages from response headers
        currentPage: page,
      });
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      this.setState({ loading: false }); // Set loading to false on error
    }
  };

  fetchBannerData = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/wp-json/wp/v2/pages/?slug=blog`
      );
      const data = await response.json();
      if (data.length > 0) {
        const bannerData = {
          sub_heading: data[0].acf.sub_heading,
          main_heading: data[0].acf.main_heading,
        };
        this.setState({ bannerData });
      }
    } catch (error) {
      console.error('Error fetching banner data:', error);
      this.setState({ loading: false }); 
    }
  };

  goToPage = (page) => {
    this.fetchBlogPosts(page);
    this.scrollToTop();
  };

  goToPreviousPage = () => {
    if (this.state.currentPage > 1) {
      this.goToPage(this.state.currentPage - 1);
    }
  };

  goToNextPage = () => {
    if (this.state.currentPage < this.state.totalPages) {
      this.goToPage(this.state.currentPage + 1);
    }
  };

  renderPagination() {
    const { totalPages, currentPage } = this.state;

    if (totalPages > 1) {
      return (
        <div className="pagination avt-pagination-wrap">
          {/* Left arrow */}
          <button
            onClick={this.goToPreviousPage}
            disabled={currentPage === 1}
            className="pagination-arrow"
          >
            {'<'}
          </button>

          {/* Page buttons */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => this.goToPage(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}

          {/* Right arrow */}
          <button
            onClick={this.goToNextPage}
            disabled={currentPage === totalPages}
            className="pagination-arrow"
          >
            {'>'}
          </button>
        </div>
      );
    }

    return null;
  }

  render() {
    const { posts, bannerData, loading } = this.state;

    if (loading) {
      return (
        <div className="avt_loader_wrap">
            <span className="avt_loader">
                <span className="avt_loader_inner"></span>
            </span>
        </div>
      );
    }

    return (
      <div>
        {/* blog banner sec */}
        <section className="avt-blog-banner-sec">
          <div className="container">
            <div className="row avt-blog-banner-row">
              <div className="col-lg-2">
                <h2>{bannerData.sub_heading}</h2>
              </div>
              <div className="col-lg-10">
                <h1 onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}
                  data-aos="fade-up"
                  data-aos-duration="38500"
                  data-aos-offset="300"
                  data-aos-once="false"
                >
                  {' '}
                  <AnimatedText
                    customClass="my-custom-class"
                    htmlContent={bannerData.main_heading}
                  />{' '}
                </h1>
              </div>
            </div>
          </div>
        </section>
        {/* blog banner sec end*/}

        {/* Dynamic blog posts */}
        <section className="avt-blog-sec">
          <div className="container">
            <div className="row avt-blog-row">
              <div className="col-lg-2" />
              <div className="col-lg-10">
                <div className="row avt-blogs-repeater-row">
                  {posts.map((post) => (
                    <div key={post.id} className="col-lg-6">
                      <div className={`avt-blog-wrap avt-blog-${post.id}`} id={post.id}>
                        <div className="avt-blog-img-wrap">
                          <Link
                            to={`/single/${post.slug}`}
                            onClick={this.scrollToTop}
                          >
                            <img
                              src={
                                post.featured_image ||
                                'assets/images/blog/blog-img-01.png'
                              }
                              alt=""
                            />
                          </Link>
                        </div>
                        <div className="avt-blog-content-wrap">
                          <h2 className="avt-blog-title">
                            <Link
                              to={`/single/${post.slug}`}
                              onClick={this.scrollToTop}
                            >
                              {post.title.rendered}
                            </Link>
                          </h2>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: post.excerpt.rendered,
                            }}
                          />
                          <Link
                            className="avt-btn"
                            to={`/single/${post.slug}`}
                            onClick={this.scrollToTop}
                          >
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Pagination */}
                {this.renderPagination()}
                {/* Pagination end */}

              </div>
            </div>
          </div>
        </section>
        {/* Dynamic blog posts end */}
      </div>
    );
  }
}

export default Blog;
