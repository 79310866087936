import logo from './logo.svg';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import './App.css';
import Home from './components/pages/home/home';
import About from './components/pages/about/about';
import Contact from './components/pages/contact/contact';
import Blog from './components/pages/blog/blog';
import Casestudy from './components/pages/case-study/case-study';
import WebDev from './components/pages/services/web-development';
import MobileApp from './components/pages/services/mobile-app-development';
import UiUx from './components/pages/services/ui-ux-design';
import SoftwareDev from './components/pages/services/software-development';
import ArtificalInt from './components/pages/services/artificial-intelligence';
import Single from './components/pages/single/single';
import CaseStudyDetail from './components/pages/case-study-detail/case-study-detail';
import ErrorPage from './components/ErrorPage';
import { BrowserRouter as Main, Route, Routes } from 'react-router-dom';
import CustomCursor from './components/CustomCursor';
import TermCond from './components/pages/terms-condition/terms-and-conditions';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <div>
            <Main>
                <Header />
                <CustomCursor />
                <ScrollToTop />
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/web-development' element={<WebDev />} />
                    <Route path='/mobile-app-development' element={<MobileApp />} />
                    <Route path='/ui-ux-design' element={<UiUx />} />
                    <Route path='/software-development' element={<SoftwareDev />} />
                    <Route path='/artificial-intelligence' element={<ArtificalInt />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/single/:slug' element={<Single />} />
                    <Route path='/case-study' element={<Casestudy />} />
                    <Route path='/case-study-detail/:slug' element={<CaseStudyDetail />} />
                    <Route path='/terms-and-conditions' element={<TermCond />} />
                    {/* Error route should be the last one */}
                    <Route path='*' element={<ErrorPage />} />
                </Routes>
                <Footer />
            </Main>
        </div>
    );
}

export default App;
