import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import AnimatedText from '../../animation/AnimatedText';
import FancyAnimation01 from '../../animation/FancyAnimation01';
import FadeAnimation from '../../animation/FadeAnimation';
import baseUrl from '../../../baseUrl';
import Aos from 'aos';
import 'aos/dist/aos.css';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CustomCursor from '../../CustomCursor';

import Sticky from 'react-sticky-el';
import { Animator, ScrollContainer, ScrollPage, batch, Fade, Move, MoveIn, MoveOut, Sticky as ScrollSticky, StickyOut, StickyIn, ZoomIn } from "react-scroll-motion";

const ZoomInScrollOut = batch(StickyIn(), Fade(), ZoomIn());
const FadeUp = batch(Fade(), Move(), ScrollSticky());

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      homeData: null,
      isLoading: true,
    };
    this.whyChooseUsRef = React.createRef();
    this.handleWheel = this.handleWheel.bind(this);
    this.isScrolling = false;

    this.ZoomInScrollOut = batch(StickyIn(), Fade(), ZoomIn());
    this.FadeUp = batch(Fade(), Move());

    this.FlexCenterStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    };

  }

  handleMouseEnter = () => {
    CustomCursor.setHovered(true);
  };

  handleMouseLeave = () => {
    CustomCursor.setHovered(false);
  };

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    // Replace the URL with your actual WordPress API URL
    // const apiUrl = 'http://localhost/wp-avanttech3/wp-json/wp/v2/pages/?slug=home';

    const apiPath = '/wp-json/wp/v2/pages/?slug=home';
    const apiUrl = `${baseUrl}${apiPath}`;

    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        // Assuming that the first item in the response array contains the ACF data
        const homePageSettings = data[0]?.acf?.home_page_settings;

        this.setState({
          homeData: homePageSettings,
          isLoading: false,
        });
      })
      // .catch(error => console.error('Error fetching data:', error));
      .catch(error => {
        console.error('Error fetching data:', error);
        this.setState({
          isLoading: false, // Set loading to false in case of an error
        });
      });

    Aos.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Set to true if you want the animation to occur only once
    });

    // Add scroll event listener
    window.addEventListener('wheel', this.handleWheel);

    /////////////////////
  }


  componentWillUnmount() {
    // Remove scroll event listener when the component is unmounted
    window.removeEventListener('wheel', this.handleWheel);
  }


  componentDidUpdate() {
    const servicesRow = document.querySelector('.avt-services-row-01');
  }


  // old handleWheel method
  // handleWheel = () => {
  //   if (!this.isScrolling && this.whyChooseUsRef.current) {
  //     const rect = this.whyChooseUsRef.current.getBoundingClientRect();
  //     const inViewport = rect.top < window.innerHeight && rect.bottom >= 0;

  //     if (inViewport) {
  //       this.isScrolling = true; // Set the flag to true to indicate scrolling initiated by the script
  //       this.whyChooseUsRef.current.scrollIntoView({ behavior: 'smooth' });
  //       gsap.to(this.whyChooseUsRef.current, {
  //         scale: 1,
  //         duration: 2.0,
  //         ease: 'power2.inOut',
  //         onComplete: () => {
  //           this.isScrolling = false; // Reset the flag when the animation is complete
  //         },
  //       });
  //     }
  //   }
  // };

  //method 2
  // handleWheel(event) {
  //   event.preventDefault();

  //   // Check if the screen width is more than 1024 pixels
  //   if (window.innerWidth <= 1024) {
  //     return;
  //   }

  //   const rect = this.whyChooseUsRef.current.getBoundingClientRect();
  //   const inViewport = rect.top <= 0 && rect.bottom >= 0;

  //   const sectionRef = this.whyChooseUsRef.current;

  //   // Adjust the step size based on your preference
  //   const stepSize = 0.3;

  //   // Determine the direction of the scroll
  //   const direction = event.deltaY > 0 ? 1 : -1;

  //   // Get the distance from the top of the viewport to the top of the element
  //   const distanceFromTop = this.whyChooseUsRef.current.getBoundingClientRect();

  //   // Check if the top of the element is in the viewport
  //   const isTopInViewport = rect.top <= 0 && rect.bottom >= 0;

  //   if (isTopInViewport) {
  //     // Get the current scale from the computed style
  //     const currentScale = parseFloat(window.getComputedStyle(sectionRef).transform.split(',')[3] || 1);

  //     // Calculate the new scale within the specified range
  //     const newScale = Math.min(Math.max(0.0, currentScale + direction * stepSize), 1);

  //     // Apply the scale directly
  //     sectionRef.style.transform = `scale(${newScale})`;

  //     // Set isScrolling to true and reset it after a delay
  //     this.isScrolling = true;
  //     setTimeout(() => {
  //       this.isScrolling = false;
  //     }, 500);
  //   }
  // }


  handleWheel(event) {
    event.preventDefault();

    // Check if the screen width is more than 1024 pixels
    if (window.innerWidth <= 1024) {
      return;
    }

    const rect = this.whyChooseUsRef.current.getBoundingClientRect();

    // Check if the top of the element is in the viewport and scrolling down
    const isTopInViewport = rect.top <= 0 && rect.bottom >= 0 && event.deltaY > 0;

    if (isTopInViewport) {
      const sectionRef = this.whyChooseUsRef.current;

      // Adjust the step size based on your preference
      const stepSize = 0.4;

      // Determine the direction of the scroll
      const direction = 1; // Since isTopInViewport ensures scrolling down

      // Get the current scale from the computed style
      const currentScale = parseFloat(window.getComputedStyle(sectionRef).transform.split(',')[3] || 1);

      // Calculate the new scale within the specified range
      const newScale = Math.min(Math.max(0.0, currentScale + direction * stepSize), 1);

      // Apply the scale directly
      sectionRef.style.transform = `scale(${newScale})`;



      // Listen for the 'transitionend' event to add the class after the scale reaches 1
      const transitionEndHandler = () => {
        if (newScale === 1) {
          // Find the element with the class .avt-why-choose-sec
          const avtWhyChooseSec = document.querySelector('.avt-why-choose-sec');

          if (avtWhyChooseSec) {
            avtWhyChooseSec.classList.add('avt-why-choose-animate-compl'); // Replace 'your-added-class' with the desired class name
          }
        }

        sectionRef.removeEventListener('transitionend', transitionEndHandler);
      };

      sectionRef.addEventListener('transitionend', transitionEndHandler);



      // Set isScrolling to true and reset it after a delay
      this.isScrolling = true;
      setTimeout(() => {
        this.isScrolling = false;
      }, 500);
    }
  }


  renderBannerSection(layout) {
    return (
      <section key={layout.acf_fc_layout} className="avt-home-banner">
        <video preload="auto" className="avt-banner-video-cls" autoPlay muted playsInline loop poster="/assets/images/home/avanttech-video-poster3.png" src={layout.video}></video>
        <div className="container avt-content-container">
          <div className="row">
            <div className="col-lg-6">
              <div data-aos="fade-up" data-aos-duration="1200" data-aos-offset="-600" data-aos-once="true" dangerouslySetInnerHTML={{ __html: layout.content }} />
              <br />
              <Link data-aos="fade-up" data-aos-duration="1500" data-aos-offset="-600" data-aos-once="true" className="avt-btn" to={layout.button?.url}>
                <span>{layout.button?.title}</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderBelowBannerSection(layout) {
    return (
      <section key={layout.acf_fc_layout} className="avt-home-brands-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <h2 onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}> <FancyAnimation01
                customclassName="my-custom-class"
                htmlContent={layout.top_heading}
              /> </h2> */}

              <h2 onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} data-aos="fade-up" data-aos-duration="38500" data-aos-offset="300" data-aos-once="false"> <AnimatedText
                customClass="my-custom-class"
                htmlContent={layout.top_heading}
              /> </h2>

              {/* <h2 onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <div data-aos="fade-up" data-aos-duration="400" data-aos-offset="340" data-aos-once="false">
                  EMPOWERING BRANDS
                </div>
                <div data-aos="fade-up" data-aos-duration="420" data-aos-offset="340" data-aos-once="false">
                  WITH EFFECTIVE DIGITAL
                </div>
                <div data-aos="fade-up" data-aos-duration="440" data-aos-offset="340" data-aos-once="false">
                  PRODUCTS AND SOLUTIONS
                </div>
              </h2> */}

              <p data-aos="fade-up" data-aos-duration="12500" data-aos-offset="-300" data-aos-once="false" >
                {layout.sub_heading}
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderAboutSection(layout) {
    return (
      <section className="avt-home-about-sec">
        <div className="container">
          <div className="row">
            <div className="avt-home-about-img-wrap">
              <img data-aos="fade-up" data-aos-duration="1200" data-aos-offset="0" data-aos-once="false" src={layout.image} alt="" />
            </div>
            <div className="col-lg-6">
              {/*    <img data-aos="fade-up" data-aos-duration="1200" data-aos-offset="-300" data-aos-once="false" src={layout.image} alt="" /> */}
            </div>
            <div className="col-lg-6">
              <div className="avt-content-sec">
                <div data-aos="fade-left" data-aos-duration="1200" data-aos-offset="0" data-aos-once="false" dangerouslySetInnerHTML={{ __html: layout.content }} />
                <Link data-aos="fade-up" data-aos-duration="1500" data-aos-offset="60" data-aos-once="false" className="avt-btn" to={layout.button?.url}>
                  <span>{layout.button?.title}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderServicesSection(layout) {
    return (
      <section key={layout.acf_fc_layout} className="avt-home-services-sec">
        <div className="container">
          <div className="row avt-home-services-top-row">
            <div className="col-lg-12">
              <div data-aos="fade-up" data-aos-duration="2500" data-aos-offset="100" data-aos-once="false" dangerouslySetInnerHTML={{ __html: layout.content }} />
            </div>
          </div>


          <div className='avt-animate-wrap'>
            <ScrollContainer className='avt-animate-cls'>

              <ScrollPage className='avt-animate-1-cls'>
              </ScrollPage>
              <ScrollPage className='avt-animate-2-cls'>
              </ScrollPage>
              <ScrollPage className='avt-animate-3-cls'>
              </ScrollPage>

              {/* <ScrollPage className='avt-last'>
              </ScrollPage> */}

              <ScrollPage className='avt-animate-4-cls'>
                <Animator animation={FadeUp}>
                  {/* service 1 */}
                  <div className="row avt-services-row avt-services-row-01">
                    <div className="col-lg-6">

                      <div className="avt-home-services-img-wrap">
                        <Animator animation={MoveIn(0, -200)}>
                          <img className='avt-home-service-1-bg-img' src="assets/images/home/web-dev-back-icon-1.png" alt="" srcSet="" />
                        </Animator>
                        <Animator animation={MoveIn(-1000, 0)}>
                          <img className='avt-home-service-2-bg-img' src="assets/images/home/web-dev-back-icon-1.png" alt="" srcSet="" />
                        </Animator>
                        <Animator animation={MoveIn(100, 0)}>
                          <img className='avt-home-service-4-bg-img' src="assets/images/home/web-dev-back-icon-1.png" alt="" srcSet="" />
                        </Animator>
                        <Animator animation={MoveIn(-400, 0)}>
                          <img src={layout.service_1?.image} alt="" />
                        </Animator>
                      </div>

                    </div>
                    <div className="col-lg-6">
                      <Animator animation={MoveIn(0, 400)}>
                        <div className="avt-home-services-content-wrap">
                          <div className="avt-content-sec">
                            <div dangerouslySetInnerHTML={{ __html: layout.service_1?.content }} />
                            <Link className="avt-btn" to={layout.service_1.button?.url}>
                              <span>{layout.service_1.button?.title}</span>
                            </Link>
                          </div>
                        </div>
                      </Animator>
                    </div>
                  </div>
                  {/* service 1 end */}
                </Animator>
              </ScrollPage>

              <ScrollPage className='avt-animate-5-cls'>
                <Animator animation={FadeUp}>

                  {/* service 2 */}
                  <div className="row avt-services-row avt-services-row-02">
                    <div className=" avt-services-02-img">
                      <Animator animation={MoveIn(1000, 0)}>
                        <img src="assets/images/home/service-img-02-back.png" alt="" />
                      </Animator>
                    </div>
                    <div className="col-lg-6 avt-row-2-col-1">
                      <Animator animation={MoveIn(-2000, 0)}>
                        <div className="avt-home-services-content-wrap">
                          <div className="avt-content-sec">
                            <div dangerouslySetInnerHTML={{ __html: layout.service_2?.content }} />
                            <Link className="avt-btn" to={layout.service_2.button?.url}>
                              <span>{layout.service_2.button?.title}</span>
                            </Link>
                          </div>
                        </div>
                      </Animator>
                    </div>
                    <div className="col-lg-6">
                      <Animator animation={MoveIn(0, 1000)}>
                        <div className="avt-home-services-img-wrap">
                          <img src={layout.service_2?.image} alt="" />
                        </div>
                      </Animator>
                    </div>
                  </div>
                  {/* service 2 end */}

                </Animator>
              </ScrollPage>

              <ScrollPage className='avt-animate-6-cls'>
                <Animator animation={FadeUp}>
                  {/* service 3 */}
                  <div className="row avt-services-row avt-services-row-03">
                    <div className="col-lg-6">
                      <Animator animation={MoveIn(-2000, 0)}>
                        <div className="avt-home-services-img-wrap">
                          <img src={layout.service_3?.image} alt="" />
                        </div>
                      </Animator>
                    </div>
                    <div className="col-lg-6">
                      <Animator animation={MoveIn(0, 1000)}>
                        <div className="avt-home-services-content-wrap">
                          <div className="avt-content-sec">
                            <div dangerouslySetInnerHTML={{ __html: layout.service_3?.content }} />
                            <Link className="avt-btn" to={layout.service_3.button?.url}>
                              <span>{layout.service_3.button?.title}</span>
                            </Link>
                          </div>
                        </div>
                      </Animator>
                    </div>
                  </div>
                  {/* service 3 end */}
                </Animator>
              </ScrollPage>

              <ScrollPage className='avt-animate-7-cls'>
                <Animator animation={FadeUp}>
                  {/* service 4 */}
                  <div className="row avt-services-row avt-services-row-04">
                    <div className="col-lg-6 avt-row-4-col-1">
                      <Animator animation={MoveIn(0, 1500)}>
                        <div className="avt-home-services-content-wrap">
                          <div className="avt-content-sec">
                            <div dangerouslySetInnerHTML={{ __html: layout.service_4?.content }} />
                            <Link className="avt-btn" to={layout.service_4.button?.url}>
                              <span>{layout.service_4.button?.title}</span>
                            </Link>
                          </div>
                        </div>
                      </Animator>
                    </div>
                    <div className="col-lg-6">
                      <Animator animation={MoveIn(1500, 0)}>
                        <div className="avt-home-services-img-wrap">
                          <img src={layout.service_4?.image} alt="" />
                        </div>
                      </Animator>
                    </div>
                  </div>
                  {/* service 4 end */}

                </Animator>
              </ScrollPage>

              <ScrollPage className='avt-animate-8-cls'>
                <Animator animation={FadeUp}>
                  {/* service 5 */}
                  <div className="row avt-services-row avt-services-row-05">
                    <div className="col-lg-6">
                      <div className="avt-home-services-img-wrap">
                        <Animator animation={MoveIn(-1500, 1000)}>
                          <img className='avt-service-5-back-img' src='https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/ai-img-bg.png' alt="" />
                        </Animator>
                        <Animator animation={MoveIn(-2000, 0)}>
                          <img className='avt-service-5-front-img' src={layout.service_5?.image} alt="" />
                        </Animator>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <Animator animation={MoveIn(0, 1000)}>
                        <div className="avt-home-services-content-wrap">
                          <div className="avt-content-sec">
                            <div dangerouslySetInnerHTML={{ __html: layout.service_5?.content }} />
                            <Link className="avt-btn" to={layout.service_5.button?.url}>
                              <span>{layout.service_5.button?.title}</span>
                            </Link>
                          </div>
                        </div>
                      </Animator>
                    </div>
                  </div>
                  {/* service 5 end */}
                </Animator>
              </ScrollPage>

            </ScrollContainer>

          </div>
          {/* groups */}

          {/* groups end */}
        </div>
      </section>
    );
  }

  renderWhyChooseUsSection(layout) {
    return (
      <section key={layout.acf_fc_layout} className="avt-why-choose-sec avt-why-choose-animate-wrap">

        <Sticky boundaryElement=".avt-why-choose-animate-wrap" hideOnBoundaryHit={false}>
          <div ref={this.whyChooseUsRef} className="avt-why-choose-wrap">
            <div className="container">
              <div className="row avt-top-head-row">
                <div className="col-lg-2">
                  <h6 data-aos="fade-right" data-aos-duration="8500" data-aos-offset="200">{layout.heading}</h6>
                </div>
                <div className="col-lg-10">
                  <div className="avt-why-choose-list-wrap">
                    <ul>
                      {layout.text_list.map(item => (
                        <li key={item.name}>
                          <h2 data-aos="fade-up" data-aos-duration="16500" data-aos-offset="200" data-aos-once="false">{item.name}</h2>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sticky>

      </section>
    );
  }

  renderPortfolioSection(layout) {
    return (
      <section key={layout.acf_fc_layout} className="avt-portfolio-sec">
        <div className="container">
          <div data-aos="fade-up" data-aos-duration="1000" data-aos-offset="300" data-aos-once="false" className="row">
            <div className="avt-portfolio-top-row">
              <div className="col-lg-6">
                <h2>
                  <div dangerouslySetInnerHTML={{ __html: layout.heading }}></div></h2>
              </div>
              <div className="row">
                <div className="col-lg-6 text-left">
                  <p>{layout.text}</p>
                </div>
                <div className="col-lg-6 text-right">
                  <a className="avt-btn" href={layout.button?.url}>
                    <span>{layout.button?.title}</span>
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-5 p-0">
                <Link onClick={this.scrollToTop} to={layout.portfolio_grid.portfolio_option_1.url?.url}>
                  <div data-aos="zoom-in" data-aos-duration="300" data-aos-offset="150" data-aos-once="false" className="avt-portfolio-box">
                    <div onClick={this.scrollToTop} className="avt-img-wrap">
                      <img src={layout.portfolio_grid.portfolio_option_1?.image} alt="" />
                    </div>
                    <div className="avt-portfolio-hover-btn">
                      <img src="assets/images/home/hover-btn.png" alt="" srcSet="" />
                    </div>
                  </div>
                </Link>
                <Link onClick={this.scrollToTop} to={layout.portfolio_grid.portfolio_option_2.url?.url}>
                  <div data-aos="zoom-in" data-aos-duration="360" data-aos-offset="200" data-aos-once="false" className="avt-portfolio-box">
                    <div className="avt-img-wrap">
                      <img src={layout.portfolio_grid.portfolio_option_2?.image} alt="" />
                    </div>
                    <div className="avt-portfolio-hover-btn">
                      <img src="assets/images/home/hover-btn.png" alt="" srcSet="" />
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-7 p-0">
                <Link onClick={this.scrollToTop} to={layout.portfolio_grid.portfolio_option_3.url?.url}>
                  <div data-aos="zoom-in" data-aos-duration="300" data-aos-offset="300" data-aos-once="false" className="avt-portfolio-box">
                    <div className="avt-img-wrap">
                      <img src={layout.portfolio_grid.portfolio_option_3?.image} alt="" />
                    </div>
                    <div className="avt-portfolio-hover-btn">
                      <img src="assets/images/home/hover-btn.png" alt="" srcSet="" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </section>
    );
  }

  renderTestimonialsSection(layout) {
    return (
      <section key={layout.acf_fc_layout} className="avt-testimonials-sec">
        <div className="container">
          <Carousel>
            {layout.testimonials.map((testimonial, index) => (
              <Carousel.Item key={index}>
                <div className="row">
                  <div className="col-lg-4 col-left-tesi-wrap">
                    <div className="avt-testimonial-img-wrap">
                      <img className="avt-testimonail-img" src={testimonial.image} alt="" />
                    </div>
                  </div>
                  <div className="col-lg-8 avt-testimonials-main-wrap">
                    <h6>{layout.heading}</h6>
                    <div className="avt-testimonials-wrap">
                      <h4>{testimonial.content}</h4>
                      <div className="avt-testimonials-designation">
                        <div dangerouslySetInnerHTML={{ __html: testimonial.designation }} />
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </section>
    );
  }

  render() {
    const { homeData, isLoading } = this.state;

    if (isLoading) {
      return <div className="avt_loader_wrap">
        <span className="avt_loader">
          <span className="avt_loader_inner"></span>
        </span>
      </div>;
    }


    return (
      <div>
        {homeData && homeData.map(layout => (
          <React.Fragment key={layout.acf_fc_layout}>
            {layout.acf_fc_layout === 'banner_section' && this.renderBannerSection(layout)}
            {layout.acf_fc_layout === 'below_banner_section' && this.renderBelowBannerSection(layout)}
            {layout.acf_fc_layout === 'about_section' && this.renderAboutSection(layout)}
            {layout.acf_fc_layout === 'services_section' && this.renderServicesSection(layout)}
            {layout.acf_fc_layout === 'why_choose_us_section' && this.renderWhyChooseUsSection(layout)}
            {layout.acf_fc_layout === 'portfolio_section' && this.renderPortfolioSection(layout)}
            {layout.acf_fc_layout === 'testimonials_section' && this.renderTestimonialsSection(layout)}
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default Home;
