import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import Aos from 'aos';
import baseUrl from '../../baseUrl';
import AnimatedText from '../animation/AnimatedText';

const Header = () => {
    const [expanded, setExpanded] = useState(false);
    const [megaMenuVisible, setMegaMenuVisible] = useState(false);
    const [jsonData, setJsonData] = useState(null);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleNavSelect = () => {
        setExpanded(false);
        setMegaMenuVisible(false);
    };

    const handleNavbarToggle = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const openMegaMenu = () => {
        setMegaMenuVisible(true);
    };

    const closeMegaMenu = () => {
        setMegaMenuVisible(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                window.scrollTo(0, 0);
                const response = await fetch(`${baseUrl}/wp-json/wp/v2/pages/?slug=option`);
                const data = await response.json();
                setJsonData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        Aos.init({
            duration: 1000,
            once: true,
        });

        ///
        // Add or remove 'overflow-hidden' class based on megaMenuVisible state
        if (megaMenuVisible) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = '';
        }

        fetchData();
    }, [megaMenuVisible]);

    if (!jsonData) {
        return (
            <div className="avt_loader_wrap">
                <span className="avt_loader">
                    <span className="avt_loader_inner"></span>
                </span>
            </div>
        );
    }

    const { acf } = jsonData[0];

    return (
        <div>
            {/* header */}
            <header className="avt-header-wrap">
                <div className="container-fluid">
                    <div className="row col-header-row">
                        <div className="col-lg-5 col-8 avt-menu-col-cls">
                            <div className="avt-megamenu-icon">
                                <a href="#" className="mega-btn" onClick={openMegaMenu}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path opacity="1" fill="#fff" d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-2 col-4 text-center">
                            <Link to="/" onClick={scrollToTop}>
                                <img src={acf.logo_image} alt="Avant tech logo" />
                            </Link>
                        </div>

                        <div className="col-lg-5 col-5 text-right avt-header-social-list">

                            <ul className="avt-header-social-list">
                                <li>
                                    <Link to={`tel:${acf.phone_number}`}>
                                        <img src="assets/images/contact/avt-call-icon.png" alt="" />
                                        <span>
                                            {acf.phone_number}
                                        </span>
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link to={`mailto:${acf.email}`}>
                                        <img src="assets/images/contact/avt-message-icon.png" alt="" />
                                        <span>
                                            {acf.email}
                                        </span>
                                    </Link>
                                </li> */}
                            </ul>

                        </div>
                    </div>
                </div>
            </header>
            {/* header css */}

            {megaMenuVisible && (
                <section className="avt-mega-menu">

                    <div className="container-fluid">

                        <div className="row avt-top-row col-header-row" data-aos="fade-down" data-aos-duration="1200" data-aos-offset="0" data-aos-once="false">
                            <div className="col-lg-5 col-8 avt-menu-col-cls">
                                <div className="avt-close-menu-btn" onClick={closeMegaMenu}>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.474 1.15704L8.90708 7.72397L2.34015 1.15704C2.18221 1.0125 1.97459 0.934482 1.76055 0.939225C1.54651 0.943967 1.34255 1.03111 1.19116 1.1825C1.03977 1.33388 0.952632 1.53784 0.94789 1.75188C0.943147 1.96593 1.02117 2.17354 1.1657 2.33149L7.7293 8.89842L1.16404 15.4637C1.08383 15.5401 1.01971 15.6318 0.975451 15.7334C0.931193 15.835 0.907686 15.9444 0.906314 16.0552C0.904942 16.1659 0.925732 16.2759 0.967461 16.3785C1.00919 16.4812 1.07102 16.5744 1.14931 16.6528C1.2276 16.7312 1.32077 16.7932 1.42335 16.8351C1.52594 16.877 1.63585 16.8979 1.74664 16.8967C1.85744 16.8955 1.96687 16.8721 2.0685 16.828C2.17014 16.7839 2.26194 16.7199 2.33849 16.6398L8.90708 10.0745L15.474 16.6415C15.632 16.786 15.8396 16.864 16.0536 16.8593C16.2677 16.8545 16.4716 16.7674 16.623 16.616C16.7744 16.4646 16.8615 16.2607 16.8663 16.0466C16.871 15.8326 16.793 15.625 16.6485 15.467L10.0815 8.90008L16.6485 2.33149C16.7287 2.25505 16.7928 2.16335 16.837 2.06177C16.8813 1.9602 16.9048 1.8508 16.9062 1.74001C16.9076 1.62922 16.8868 1.51927 16.845 1.41663C16.8033 1.31399 16.7415 1.22073 16.6632 1.14233C16.5849 1.06393 16.4917 1.00197 16.3891 0.960093C16.2866 0.918218 16.1766 0.897272 16.0659 0.898487C15.9551 0.899703 15.8456 0.923054 15.744 0.967169C15.6424 1.01128 15.5506 1.07527 15.474 1.15537V1.15704Z" fill="white" />
                                    </svg>

                                </div>
                            </div>

                            <div className="col-lg-2 col-4 text-center">
                                <Link to="/" onClick={closeMegaMenu}>
                                    <img src={acf.logo_image} alt="Avant tech logo" />
                                </Link>
                            </div>

                            <div className="col-lg-5 col-5 text-right avt-header-social-list">

                                <ul className="avt-header-social-list">
                                    <li>
                                        <Link to={`tel:${acf.phone_number}`}>
                                            <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/avt-call-icon.png" alt="" />
                                            <span>
                                                {acf.phone_number}
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`mailto:${acf.email}`}>
                                            <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/avt-message-icon.png" alt="" />
                                            <span>
                                                {acf.email}
                                            </span>
                                        </Link>
                                    </li>
                                </ul>

                            </div>
                        </div>

                        <div className=''>
                            <div className="row avt-middle-row">
                                <div className='col-lg-3'>

                                    {/* nav menu main */}
                                    <div className='avt-mobile-menu-cls avt-mega-menu-main-cls' data-aos="fade-up" data-aos-duration="900" data-aos-offset="0" data-aos-once="false">
                                        {/*  nav menu */}
                                        <Navbar expand="lg" expanded={expanded} onToggle={handleNavbarToggle}>
                                            <Navbar.Toggle id="navbar-toggler" aria-controls="basic-navbar-nav" />
                                            <Navbar.Collapse id="basic-navbar-nav">
                                                <Nav className="me-auto" onSelect={handleNavSelect}>
                                                    <Nav.Link as={Link} to="/about" onClick={() => { scrollToTop(); handleNavSelect(); }}
                                                    >
                                                        About Us
                                                    </Nav.Link>
                                                    <NavDropdown title="Services" id="basic-nav-dropdown">
                                                        <NavDropdown.Item as={Link} to="/web-development" onClick={() => { scrollToTop(); handleNavSelect(); }}>
                                                            Web Development
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Divider />
                                                        {/* Add other NavDropdown items here */}
                                                        <NavDropdown.Item as={Link} to="/mobile-app-development" onClick={() => { scrollToTop(); handleNavSelect(); }}>
                                                            Mobile App Development
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Divider />
                                                        <NavDropdown.Item as={Link} to="/ui-ux-design" onClick={() => { scrollToTop(); handleNavSelect(); }}>
                                                            UI/UX Design
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Divider />
                                                        <NavDropdown.Item as={Link} to="/software-development" onClick={() => { scrollToTop(); handleNavSelect(); }}>
                                                            Software Development
                                                        </NavDropdown.Item>
                                                        <NavDropdown.Divider />
                                                        <NavDropdown.Item as={Link} to="/artificial-intelligence" onClick={() => { scrollToTop(); handleNavSelect(); }}>
                                                            Artificial Intelligence
                                                        </NavDropdown.Item>
                                                    </NavDropdown>

                                                    <Nav.Link as={Link} to="/case-study" onClick={() => { scrollToTop(); handleNavSelect(); }} data-aos="fade-up" data-aos-duration="900" data-aos-offset="0" data-aos-once="false">
                                                        Case Studies
                                                    </Nav.Link>
                                                    <Nav.Link as={Link} to="/blog" onClick={() => { scrollToTop(); handleNavSelect(); }} data-aos="fade-up" data-aos-duration="1200" data-aos-offset="0" data-aos-once="false">
                                                        Blog
                                                    </Nav.Link>
                                                    {/* <Nav.Link as={Link} to="/contact" onClick={() => { scrollToTop(); handleNavSelect(); }}
                                                    data-aos="fade-up" data-aos-duration="1500" data-aos-offset="0" data-aos-once="false">
                                                    Contact
                                                </Nav.Link> */}
                                                </Nav>
                                            </Navbar.Collapse>
                                        </Navbar>
                                        {/*  nav menu end */}
                                    </div>
                                    {/* nav menu end */}

                                    <Link className='avt-project-btn' to='/contact' onClick={closeMegaMenu}
                                        data-aos="fade-up" data-aos-duration="1600" data-aos-offset="0" data-aos-once="false"
                                    >
                                        Start a Projects <span>
                                            <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/charm_arrow-up.png" alt="" />
                                        </span>
                                    </Link>

                                    <ul className="avt-resp-social-list avt-header-social-list">
                                        <li>
                                            <Link to={`tel:${acf.phone_number}`}>
                                                <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/avt-call-icon.png" alt="" />
                                                <span>
                                                    {acf.phone_number}
                                                </span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={`mailto:${acf.email}`}>
                                                <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/avt-message-icon.png" alt="" />
                                                <span>
                                                    {acf.email}
                                                </span>
                                            </Link>
                                        </li>
                                    </ul>



                                    <div className="avt-footer-icons-list" data-aos="fade-up" data-aos-duration="1900" data-aos-offset="0" data-aos-once="false">
                                        <ul>
                                            <li>
                                                <a href={acf.facebook} target='_blank'>
                                                    <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/fb-icon-1.png" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href={acf.insta} target='_blank'>
                                                    <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/insta-icon-1.png" alt="" />
                                                </a>
                                            </li>
                                            <li>
                                                <a href={acf.linkedin} target='_blank'>
                                                    <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/linkedion-icon-1.png" alt="" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>

                                <div className='col-lg-9 avt-right-col' data-aos="fade-right" data-aos-duration="1600" data-aos-offset="0" data-aos-once="false">
                                    {/* <video preload="auto" className="avt-banner-video-cls" autoPlay muted playsInline loop poster="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/video-thumbnial.png" src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/menu-video-01.mp4"></video> */}
                                    <img src="https://backend.avt.avantechdev.com.au/wp-content/uploads/2024/01/menu-img-1.png" alt="" />
                                </div>

                            </div>

                        </div>

                    </div>

                </section>
            )}
        </div>
    );
};

export default Header;
